* {
    outline: none;
    transition: all 0.3s;
}

body {
    font-family: 'Heebo', sans-serif;
}

.bg-home {
    /* background-image: url("https://images.unsplash.com/photo-1501503069356-3c6b82a17d89?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80"); */
    
    background:  url('https://images.unsplash.com/photo-1517816743773-6e0fd518b4a6?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    /* background-attachment: fixed; */
}

.bg-finished {
    /* background-image: url("https://images.unsplash.com/photo-1501503069356-3c6b82a17d89?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80"); */
    
    background: linear-gradient( rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8) ), url('https://images.unsplash.com/photo-1529022805552-1c88a713c1c5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1225&q=80');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.up-on-hover:hover {
    transform: translateY(-1px);
}

.transition-quick {
    transition: all 0.01s !important;
}

.call-video {
    transform: scale(-1, 1);
}